import PropTypes, { element, func } from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "../../assets/scss/style.css";
import { useFormik } from "formik";
import * as Yup from "yup";

import "flatpickr/dist/themes/material_blue.css";

import { withTranslation } from "react-i18next";

import { apiRoute } from "routes/apiRoutes";
import { LoaderContext } from "context/ContextProvider";
import axios from "axios";
import { toast } from "react-toastify";
import {
  dateformat,
} from "admin/commonFunction";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import { getAllMigrated } from "services/userServices";
import { fireSuccessToast } from "common/toast";
import { humanReadableNumber } from "common/utility";
import moment from "moment";

const KycUsers = (props) => {
  const {
    setloading,
  } = useContext(LoaderContext);

  document.title = "Users";
  const [listingData, setData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loaderStatus, setLoaderStatus] = useState(true);
  let [incermentInd, setIncrementInd] = useState(1);
  const perPage = 10;

  //fetch users
  const fetchData = async (pagenumber, pagelimit, search = "", status = "", exportRequest = false) => {
    try {
      setLoaderStatus(true);

      // Check if exportRequest is true
      const result = await getAllMigrated(
        pagenumber,
        pagelimit,
        exportRequest
      );

      if (exportRequest) {
        setLoaderStatus(false);
      } else {
        // Regular data loading for display
        setData(result.data?.data2);
        let pageCount = Math.ceil(result?.data?.totalCount / perPage);
        setTotalCount(pageCount);
        setLoaderStatus(false);
      }
    } catch (err) {
      setLoaderStatus(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData(currentPage, perPage);
  }, []);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    fetchData(page, perPage, searchName, statusFilter);
  };



  const [modal, setmodal] = useState(false);
  const toggleModal = () => {
    setmodal(!modal);
  };
  //register user
  const handleSubmit = (values) => {
    console.log(values, "ac");
    const data = new FormData();
    for (const key in values) {
      data.append(key, values[key]);
    }
    setloading(true);
    try {
      axios
        .post(apiRoute.registerUser, data)
        .then((res) => {
          console.log(res.data.message, "jhkkl;");
          setloading(false);
          toast.success(res.data.message);
          toggleModal();
          fetchData(1, perPage);
        })
        .catch((err) => {
          console.log(err, "gfhgjkjlk");
          setloading(false);
          toast.error(err.response.data.error);
        });
    } catch (err) {
      console.log(err, "hkjhkjkh");
      setloading(false);
      toast.error("An error occurred !");
    }
  };





  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmpassword: "",
      profileImg: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("name is required"),
      email: Yup.string().required("email is required"),
      password: Yup.string().required("password is required"),
      confirmpassword: Yup.string()
        .required("confirm password is required")
        .oneOf([Yup.ref("password"), null], "passwords must match"),
      profileImg: Yup.mixed().required("profile image is required."),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  useEffect(() => {
    formik.resetForm();
  }, [modal]);
  const handleCopy = (data) => {
    navigator.clipboard.writeText(data)
    fireSuccessToast('Copied...')
}
const handleExportCSV = () => {
  fetchData(1, 1000, searchName, statusFilter, true);
};
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div>
                    <Row className="d-flex align-items-center">
                      <Col lg="4">
                        <h5 className=" text-color-gredient">Migration Users </h5>
                      </Col>
                      <Col
                        lg="8"
                        className="d-flex align-items-center justify-content-end"
                      >
                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleExportCSV}
                          >
                            Export CSV
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
                <CardBody>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th>Sr. No.</Th>
                        <Th>User</Th>
                        <Th>Wallet Address</Th>
                        <Th>Total Holdings</Th>
                        <Th>Created At</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {loaderStatus ? (
                        <Tr>
                          <Td colSpan="6"> Loading ... </Td>
                        </Tr>
                      ) : listingData?.length != 0 ? (
                        listingData?.map((element, index) => {
                          currentPage > 1
                            ? (incermentInd = (currentPage - 1) * perPage + 1)
                            : 0;
                          return (
                            <tr key={index}>
                              <td>
                                <span className="co-name">
                                  {" "}
                                  {incermentInd + index}{" "}
                                </span>
                              </td>
                              <td>
                                <span>Name : {element?.client?.name}</span><br />
                                <span>Email : {element?.client?.email}</span>
                              </td>

                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="">
                                    <h5 className="font-size-14 text-color-gredient">
                                      {element.name}
                                    </h5>
                                    <span>  
                                      ETH : {element.etheriumWalletAddress
                                        ?<> {element.etheriumWalletAddress.slice(0, 5)}...{element.etheriumWalletAddress.slice(-5)}{" "}
                                        <span onClick={() => handleCopy(element?.etheriumWalletAddress)}> <i className="mdi mdi-content-copy ms-2 fs-5" style={{ cursor: 'pointer' }} /> </span></>
                                        : ""}
                                    </span>
                                    <br />
                                    <span>
                                      Solana : {element.solanaWalletAddress
                                        ? <>{element.solanaWalletAddress.slice(0, 5)}...{element.solanaWalletAddress.slice(-5)}{" "}
                                        <span onClick={() => handleCopy(element?.solanaWalletAddress)}> <i className="mdi mdi-content-copy ms-2 fs-5" style={{ cursor: 'pointer' }} /> </span>
                                        </>
                                        : ""}
                                    </span>
                                    <br />

                                  </div>
                                </div>
                              </td>
                              <td>{humanReadableNumber(Number(element?.hommiesHoldings)?.toFixed(4))} {" "}Hommies</td>
                              <td>{moment(element.createdAt).format('lll')}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={8}>No Records Found</td>
                        </tr>
                      )}
                    </Tbody>
                  </Table>

                  <Row className="mt-2">
                    <Col md={9}></Col>
                    <Col md={3}>
                      <Pagination
                        {...bootstrap5PaginationPreset}
                        current={currentPage}
                        total={totalCount}
                        onPageChange={(page) => handlePageClick(page)}
                        className="pagination justify-content-end"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

KycUsers.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(KycUsers);
