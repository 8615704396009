import appendData from "common/utility";
import {
  del,
  get,
  getWithExportFile,
  post,
  postAsForm,
  put,
} from "helpers/api_helper";

export const addNewFaq = (data) => {
  return post("/admin/saveFaq", data);
};
export const addNewPlans = (data) => {
  return post("/plan/createPlan", data);
};
export const addNewDao = (data) => {
  return post("/dao/save", data);
};

export const updateDaoStatus = (data) => {
  return post("/dao/UpdateStatus", data);
};

export const updateNewFaq = (data) => {
  return post("/admin/saveFaq", data);
};
export const updateNewPlan = (data) => {
  return post("/plan/createPlan", data);
};

export const getFaqDetail = () => {
  return get("/admin/getFaq");
};
export const getPlanDetail = () => {
  return get("/plan/getAllPlans");
};

export const getAllProposal = () => {
  return get(`dao/getAllProposal?pageNumber=1&pageSize=200`);
};

export const deleteFaqData = (data) => {
  return del("/admin/deleteFaq", data);
};
export const deletePlanData = (data) => {
  return del("/plan/deletePlan", data);
};
export const deleteDao = (id) => {
  return del(`/dao/deleteDao?id=${id}`);
};

export const adminLogin = (data) => {
  return post("/admin/login", data);
};

export const reSendOtp = (data) => {
  return post("/admin/resendotp", data);
};

export const forgetPassword = (data) => {
  return post("/admin/forgotPassword", data);
};

export const verifyOtp = (data) => {
  return post("/admin/verifyOtp", data);
};

export const resetPassword = (new_password, token) => {
  return post(
    "/admin/resetPassword",
    { new_password },
    {
      headers: { Authorization: token },
    }
  );
};

export const getUserProfile = () => {
  return get("/admin/me");
};

export const getAllUser = (
  pageNumber,
  pageSize,
  filter = "",
  fromDate = "",
  toDate = "",
  isBlocked = "",
  kycStatus = "",
  exportRequest = "false"
) => {
  return getWithExportFile(
    `/admin/allUser?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${filter}&fromDate=${fromDate}&toDate=${toDate}&isBlocked=${isBlocked}&kycStatus=${kycStatus}&exportRequest=${exportRequest}`
  );
};
export const getAllTransactions = (pageNumber, pageSize) => {
  return getWithExportFile(
    `/transaction/getAllTransactions?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};
export const getAllMigrated = (pageNumber, pageSize,  exportRequest = "false") => {
  return getWithExportFile(
    `/migration/getMigrationData?pageNumber=${pageNumber}&pageSize=${pageSize}&exportRequest=${exportRequest}`
  );
};

export const updateProfile = (data) => {
  return post("/admin/update", data);
};
export const updateUserDetail = (data) => {
  return post("/admin/updateUserDetail", data);
};
export const airdropTransactionSave = (data) => {
  return post("/transaction/saveTransaction", data);
};
export const updateProfileImage = (data) => {
  const formData = appendData(data);
  return postAsForm("/admin/uploadImage", formData);
};

export const changePassword = (data) => {
  return post("/admin/changePassword", data);
};

export const updateWithdrawStatus = (data) => {
  return put("/admin/updateWithdrawStatus", data);
};

export const csmUpdateFlow = (data) => {
  return post("/admin/csm/update", data);
};
export const getCsmFlow = (type) => {
  return get("/admin/csm/detail?type=" + type);
};
export const dashboardStats = (type) => {
  return get("admin/dashboardStats");
};
export const transactionGraph = (type) => {
  return get("admin/transactionGraph");
};
export const userStorageInfo = (userId) => {
  return get("admin/userStorage?userId=" + userId);
};
export const userTransactionsInfo = (userId) => {
  return get("admin/userTransactions?userId=" + userId);
};

export const userKycInfo = (userId) => {
  return get("client/kyc/kycInfo?userId=" + userId);
};
export const kycStatusUpdate = ({ kycStatus, userId, reason }) => {
  return get(
    "client/kyc/kycStatusUpdate?userId=" + userId + "&kycStatus=" + kycStatus + "&reason=" + reason
  );
};
